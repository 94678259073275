import styled, {css} from 'styled-components';

import {bottomDashedLineCss} from '~components/atoms/DashedLine/styles';
import {StyledLink} from '~components/atoms/PageLink/styles';
import {SocialsContainer} from '~components/atoms/Socials/styles';
import Text from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';

export const LeftColumn = styled.div`
    display: grid;
    row-gap: 98px;
    grid-template-rows: min-content min-content;

    ${SocialsContainer} {
        justify-content: space-between;
        padding: 0;

        ${below.tablet} {
            position: relative;
            padding: 24px 0;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                inset-inline: 0;
                height: 1px;
                ${bottomDashedLineCss}
            }

            &::after {
                top: unset;
                bottom: 0;
            }
        }
    }

    ${below.tablet} {
        row-gap: 56px;
    }
`;

export const StyledFooter = styled.div<{theme?: 'light' | 'dark'}>`
    margin-top: auto;
    background: #ffffff;
    position: relative;
    overflow: hidden;

    ${({theme = 'light'}) => {
        switch (theme) {
            case 'dark': {
                return css`
                    --white: var(--dark-grey);
                    --black: #ffffff;
                    background: var(--white);
                    color: var(--black);

                    img {
                        // Unfortunately all svgs from strapi are in img tags and don't support currentColor
                        filter: invert();
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        height: 1px;
                        inset-inline: 0;
                        ${bottomDashedLineCss}

                        ${above.laptop} {
                            inset-inline: var(--h-padding);
                        }
                    }

                    ${StyledLink} {
                        :hover {
                            img {
                                filter: none !important;
                            }
                        }
                    }

                    ${LeftColumn} {
                        ${SocialsContainer} {
                            ${below.tablet} {
                                &::before,
                                &::after {
                                    /* filter: invert(); */
                                }
                            }
                        }
                    }
                `;
            }
        }
    }}
`;

export const FooterContent = styled.div`
    padding: 112px var(--h-padding) 125px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 130px;

    ${below.laptop} {
        column-gap: 40px;
    }

    ${below.tablet} {
        grid-template-columns: 1fr;
        row-gap: 56px;
        padding-top: 40px;
        padding-bottom: 64px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    ${below.tablet} {
        gap: 45px;
    }

    ${below.phoneS} {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

export const ButtonWrapper = styled.div`
    max-width: 208px;
    width: 100%;

    ${StyledLink} {
        width: 100%;
        white-space: nowrap;

        img {
            transform: translate(-1px, -2px);
        }
    }
`;

export const ButtonTextWrapper = styled(Text.bodySmallRegular)`
    max-width: 136px;
    margin: 8px 0 16px 0;
`;

export const RightColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content;
    gap: 32px 15px;

    ${below.tablet} {
        grid-template-columns: repeat(auto-fit, minmax(164px, 1fr));
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const NestedLink = styled(Text.bodySmallRegular).attrs({tag: 'span'})`
    opacity: 0.6;
`;

export const Icon = styled.img`
    max-width: 32px;
    max-height: 32px;
`;

export const ButtonIcon = styled.img`
    max-width: 16px;
    max-height: 16px;
    margin: 0 2px;
`;

export const AnimatedLogoContainer = styled.div`
    height: 200px;
    width: 100%;

    svg {
        margin-right: 188px;
    }
`;
