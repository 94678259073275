import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import React, {ReactNode, useRef} from 'react';
import {LocomotiveScrollProvider} from 'react-locomotive-scroll';
import Scroll from 'locomotive-scroll';

import {PageDataPropsType} from '~api/fetchPageData';
import {AnimationStyles} from '~components/atoms/Animations';
import {GlobalTextStyles} from '~components/atoms/Text';

import Footer from './Footer';
import {HTMLHead} from './HTMLHead';
import Menu from './Menu';
import {Container, Content} from './styles';

const EventCountdown = dynamic(
    () => import('~components/molecules/EventCountdown'),
    {ssr: false},
);

type LayoutProps = Pick<
    PageDataPropsType,
    'menu' | 'footer' | 'seo' | 'config' | 'eventCountdown'
> & {
    children?: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({
    menu,
    footer,
    eventCountdown,
    seo,
    config,
    children,
}) => {
    const scrollContainerRef = useRef(null);

    const router = useRouter();

    return (
        <>
            <HTMLHead seo={seo} />
            <GlobalTextStyles />
            <AnimationStyles />
            <LocomotiveScrollProvider
                options={{
                    smooth: true,
                    // ... all available Locomotive Scroll instance options
                }}
                watch={
                    [
                        //..all the dependencies you want to watch to update the scroll.
                        //  Basicaly, you would want to watch page/location changes
                        //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
                    ]
                }
                location={router.asPath}
                onLocationChange={(scroll: Scroll) =>
                    scroll.scrollTo(0, {duration: 0, disableLerp: true})
                } // If you want to reset the scroll position to 0 for example
                containerRef={scrollContainerRef}
            >
                <Container>
                    {menu && (
                        <Menu
                            {...menu.data.attributes}
                            socials={footer.data.attributes.socials}
                        />
                    )}
                    {eventCountdown?.data?.attributes?.show && (
                        <EventCountdown {...eventCountdown.data.attributes} />
                    )}

                    <Content ref={scrollContainerRef} data-scroll-container>
                        {children && children}
                        {footer && (
                            <Footer
                                {...footer.data.attributes}
                                theme={config?.footerTheme}
                            />
                        )}
                    </Content>
                </Container>
            </LocomotiveScrollProvider>
        </>
    );
};

export default Layout;
