import React from 'react';

import PageLink from '~atoms/PageLink';
import {LinkQuery} from '~types/graphql/LinkQuery';

import {LinkDropdown, LinkWrapperContainer, Subtext} from './styles';

const LinkWrapper = (
    link: Omit<LinkQuery, '__typename' | 'submenu' | 'appearance' | 'icon'>,
) => {
    return (
        <LinkWrapperContainer>
            <PageLink underlineOnHover link={link}>
                {link.text}
            </PageLink>
            {Boolean(link.children?.length !== 0) && (
                <LinkDropdown>
                    {link.children?.map(
                        (link, index) =>
                            link && (
                                <PageLink key={index} link={link}>
                                    {link.text}
                                    <Subtext>{link.subText}</Subtext>
                                </PageLink>
                            ),
                    )}
                </LinkDropdown>
            )}
        </LinkWrapperContainer>
    );
};

export default LinkWrapper;
