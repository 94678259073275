import {imageProps} from '~utils/imagePropsReplaceDomain';
import {GlobalComponentsQueryFooterDataAttributes} from '~types/graphql/GlobalComponentsQuery';
import PageLink from '~components/atoms/PageLink';

import {SocialsContainer} from './styles';

type SocialsProps = {
    socials: GlobalComponentsQueryFooterDataAttributes['socials'];
};

const Socials = ({socials}: SocialsProps) => {
    return (
        <SocialsContainer>
            {socials.map((link, index) => (
                <PageLink link={link} key={index}>
                    {link.icon ? <img {...imageProps(link.icon)} /> : link.text}
                </PageLink>
            ))}
        </SocialsContainer>
    );
};

export default Socials;
