import styled, {css} from 'styled-components';

import {above, below} from '~config/breakpoints';
import {StyledLink} from '~components/atoms/PageLink/styles';
import {StyledDetails} from '~components/molecules/Details/styles';
import {LaptopWrapper} from '~components/atoms/Wrapper';
import Text, {fonts} from '~components/atoms/Text';
import {H6} from '~components/download/atoms/Text';

export const FlexLaptopWrapper = styled(LaptopWrapper)`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${above.laptop} {
        display: none;
    }
`;

export const StyledMenu = styled.div`
    z-index: 100;

    position: fixed;
    inset-inline: var(--h-margin);
    top: 0;
    display: flex;
    align-items: center;

    /* background: linear-gradient(to bottom, var(--white), transparent); */
    background: var(--white);
    height: var(--topbar-height);

    padding: 0 var(--h-padding);
`;

export const MenuContent = styled.div`
    display: flex;
    position: relative;

    width: 100%;

    margin: 0 auto;

    flex-direction: row;
    height: 100%;
    align-items: center;

    justify-content: space-between;
`;

export const Logo = styled.a`
    width: 165px;
    height: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;

    img {
        max-width: 100%;
    }
`;

export const Links = styled.div<{open?: boolean}>`
    display: flex;

    ${below.laptop} {
        flex-direction: column;
        width: 100%;
        top: var(--topbar-height);
        bottom: 0;
        left: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background: white;
        gap: 40px;

        position: fixed;

        padding: 24px 24px;

        transform: translateX(100%);
        transition: all 0.5s ease-in-out;

        div:nth-child(2) ${StyledDetails} {
            margin-top: 0px;
        }

        ${({open}) =>
            open &&
            css`
                transform: translateX(0);
                transition: all 0.5s ease-in-out;
            `}
        ${H6} {
            text-transform: uppercase;
        }
    }

    ${above.laptop} {
        flex-direction: row;
        flex: 1;

        max-width: max-content;

        justify-content: space-between;

        ${StyledLink} {
            margin: 0 20px;
        }
    }
`;

export const MenuToggle = styled.button`
    height: 24px;
    width: 24px;

    ${above.laptop} {
        display: none;
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
`;

export const LinkDropdown = styled.div`
    box-shadow: 0px 2px 20px 0px #00000024;
    background-color: var(--white);
    flex-direction: column;
    gap: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    display: none;
    width: 241px;
    padding: 16px;

    ${fonts.h7Regular}

    div {
        margin: 0 !important;
    }
`;

export const LinkWrapperContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${fonts.bodyRegular}

    &:hover {
        ${LinkDropdown} {
            display: flex;
        }
    }
`;

export const FlexElement = styled.div`
    flex: 1;
`;

export const Subtext = styled(Text.bodySmallRegular)`
    color: rgba(0, 0, 0, 0.5);
`;
