import styled, {
    createGlobalStyle,
    css,
    FlattenSimpleInterpolation,
} from 'styled-components';
import {
    ComponentProps,
    FC,
    ForwardedRef,
    forwardRef,
    ReactNode,
    RefAttributes,
    useImperativeHandle,
} from 'react';
import classNames from 'classnames';

import {below} from '~config/breakpoints';

import {useInViewAnimation} from '../Animations';

import {
    fontClasses,
    fontFamilies,
    fontsConfig,
    fontStyles,
    FontStyles,
    FontConfig,
} from './config';

export {fontClasses, fontFamilies, fontsConfig, fontStyles};
export type {FontStyles, FontConfig};

const generateInterpolationFromConfig = (config: FontConfig, mobile = true) => {
    const interpolation = css`
        ${config.family && fontFamilies[config.family]}

        ${() =>
            config.weight &&
            css`
                font-weight: ${config.weight};
            `}
    ${config.size &&
        css`
            font-size: ${config.size}${typeof config.size === 'number' ? 'px' : ''};
        `}
    ${config.lineHeight &&
        css`
            line-height: ${config.lineHeight}${typeof config.lineHeight === 'number' ? 'px' : ''};
        `}
    ${config.uppercase &&
        css`
            text-transform: uppercase;
        `}

        ${mobile &&
        config.mobileFont &&
        css`
            ${below.tablet} {
                ${generateInterpolationFromConfig(
                    fontsConfig[config.mobileFont],
                    false,
                )}
            }
        `}
    ` as FlattenSimpleInterpolation;

    return interpolation;
};

export const fonts = Object.fromEntries(
    fontStyles.map((fontStyle) => [
        fontStyle,
        generateInterpolationFromConfig(fontsConfig[fontStyle], true),
    ]),
) as Record<FontStyles, FlattenSimpleInterpolation>;

export const fontsNoBreakpoints = Object.fromEntries(
    fontStyles.map((fontStyle) => [
        fontStyle,
        generateInterpolationFromConfig(fontsConfig[fontStyle], false),
    ]),
) as Record<FontStyles, FlattenSimpleInterpolation>;

const noBreakpointsClass = 'noBreakpoint';
export const richTextClass = 'richText';

export const GlobalTextStyles = createGlobalStyle`
${fontStyles.map(
    (fontStyle) => css`
        .${fontClasses[fontStyle]}:not(.${noBreakpointsClass}),
        .ck-editor__main .${fontClasses[fontStyle]}:not(.${noBreakpointsClass}) {
            ${fonts[fontStyle]}
        }
        .${fontClasses[fontStyle]}.${noBreakpointsClass},
            .ck-editor__main
            .${fontClasses[fontStyle]}.${noBreakpointsClass} {
            ${fontsNoBreakpoints[fontStyle]}
        }
    `,
)}

.ck-editor__main a, .${richTextClass} a {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;

    &:visited {
        color: inherit;
    }
}
`;

const Span = styled.span<{center?: boolean}>`
    ${({center}) =>
        center &&
        css`
            text-align: center;
        `}
`;

type RefType = HTMLElement;

type TextProps = Omit<ComponentProps<typeof Span>, 'as'> & {
    ignoreMobile?: boolean;
    className?: string;
    children?: ReactNode;
    animatation?: boolean;
    tag?: FontConfig['tag'];
};

function createComponent(
    fontStyle: FontStyles,
    {children, tag, ignoreMobile, className, animation = true, ...rest}: TextProps,
    forwardedRef: ForwardedRef<RefType>,
) {
    const {ref, animationClassName} = useInViewAnimation();

    // @ts-ignore
    useImperativeHandle(forwardedRef, () => ref.current);

    const config = fontsConfig[fontStyle];

    const effectiveTag = tag || config.tag;

    const asProp = effectiveTag ? effectiveTag : undefined;

    if (effectiveTag && !asProp) {
        throw new Error('"tag" prop must be a valid html element');
    }

    return (
        <Span
            as={asProp}
            className={classNames(
                className,
                fontClasses[fontStyle],
                ignoreMobile && noBreakpointsClass,
                animation && animationClassName,
            )}
            {...rest}
            ref={ref}
        >
            {children}
        </Span>
    );
}

const Text = Object.fromEntries(
    fontStyles.map((type) => [
        type,
        forwardRef<RefType, TextProps>((props, ref) =>
            createComponent(type, props, ref),
        ),
    ]),
) as unknown as Record<FontStyles, FC<TextProps & RefAttributes<RefType>>>;

export default Text;
