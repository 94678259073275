import React, {AnchorHTMLAttributes, useMemo} from 'react';

import {EDLink, linkProps} from '~utils/link';
import {FCC} from '~types/global';

import LinkWrapper from '../LinkWrapper';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    link?: EDLink | null;
};

const Link: FCC<LinkProps> = ({link, children, ...rest}) => {
    const {href, ...params} = useMemo(() => linkProps(link), [link]);

    return (
        <LinkWrapper href={href}>
            <a href={href} {...rest} {...params}>
                {children ?? link?.text}
            </a>
        </LinkWrapper>
    );
};

export default Link;
