import {useScopeTranslation} from '@everdome_io/next-js-helper';

import PageLink from '~components/atoms/PageLink';
import TelegramIcon from '~assets/icons/footer/telegram.svg';
import {GlobalComponentsQueryMenuDataAttributesButton} from '~types/graphql/GlobalComponentsQuery';

import {ContactButtonsContainer, ContactButtonWrapper} from './styles';

type ContactButtonsProps = {
    button: GlobalComponentsQueryMenuDataAttributesButton | null;
};

const ContactButtons = ({button}: ContactButtonsProps) => {
    const t = useScopeTranslation('menu');

    return (
        <ContactButtonsContainer>
            {button && (
                <ContactButtonWrapper>
                    <p>{t('businessOpportunities')}</p>
                    <PageLink link={{...button, type: 'buttonFilled'}}>
                        {button.text}
                    </PageLink>
                </ContactButtonWrapper>
            )}
            <ContactButtonWrapper>
                <p>{t('helpWithEverdome')}</p>
                <PageLink
                    link={{
                        target: 'blank',
                        url: '#',
                        type: 'buttonStroked',
                    }}
                >
                    <TelegramIcon />
                    {t('everdomeTelegram')}
                </PageLink>
            </ContactButtonWrapper>
        </ContactButtonsContainer>
    );
};

export default ContactButtons;
