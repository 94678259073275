import {css} from 'styled-components';

export const fontFamilies = {
    openSauceOne: css`
        font-family: 'OpenSauceOne', Arial, Helvetica, sans-serif;
    `,
    clashGrotesk: css`
        font-family: 'ClashGrotesk', Arial, Helvetica, sans-serif;
    `,
    orangeAvenue: css`
        font-family: 'OrangeAvenue', serif;
        font-feature-settings: 'ss01' on;
    `,
};

export const fontStyles = [
    'h1Regular',
    'h1Bold',
    'h1Fancy',
    'h2Regular',
    'h2Bold',
    'h2Fancy',
    'h3Regular',
    'h3Bold',
    'h3Fancy',
    'h4Regular',
    'h4Bold',
    'h4Fancy',
    'h5Regular',
    'h5Fancy',
    'h5Bold',
    'h6Regular',
    'h6Bold',
    'h6Fancy',
    'h7Regular',
    'h7Bold',
    'bodyRegular',
    'bodyBold',
    'bodySmallRegular',
    'bodySmallBold',
] as const;

export type FontStyles = typeof fontStyles[number];

export const fontClasses = Object.fromEntries(
    fontStyles.map((fontStyle) => [fontStyle, fontStyle]),
) as Record<FontStyles, string>;

export type FontConfig = {
    // Tag only affects exported Components
    tag?: keyof JSX.IntrinsicElements;
    family: keyof typeof fontFamilies;
    weight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    size: number;
    lineHeight: number | string | null;
    uppercase: boolean;
    // Font config to use on smaller devices
    mobileFont: FontStyles | null;
    letterSpacing?: string;
};

export const fontsConfig: Record<FontStyles, FontConfig> = {
    h1Regular: {
        tag: 'h1',
        family: 'clashGrotesk',
        weight: 300,
        size: 120,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h2Regular',
    },
    h1Bold: {
        tag: 'h1',
        family: 'clashGrotesk',
        weight: 400,
        size: 120,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h2Bold',
    },
    h1Fancy: {
        tag: 'h1',
        family: 'orangeAvenue',
        weight: 400,
        size: 120,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h2Fancy',
    },
    h2Regular: {
        tag: 'h2',
        family: 'clashGrotesk',
        weight: 300,
        size: 80,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h3Regular',
    },
    h2Bold: {
        tag: 'h2',
        family: 'clashGrotesk',
        weight: 400,
        size: 80,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h3Bold',
    },
    h2Fancy: {
        tag: 'h2',
        family: 'orangeAvenue',
        weight: 400,
        size: 70,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h3Fancy',
    },
    h3Regular: {
        tag: 'h3',
        family: 'clashGrotesk',
        weight: 300,
        size: 56,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h4Regular',
    },
    h3Bold: {
        tag: 'h3',
        family: 'clashGrotesk',
        weight: 400,
        size: 56,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h4Bold',
    },
    h3Fancy: {
        tag: 'h3',

        family: 'orangeAvenue',
        weight: 400,
        size: 50,
        lineHeight: '110%',
        uppercase: true,
        mobileFont: 'h4Fancy',
    },
    h4Regular: {
        tag: 'h4',
        family: 'clashGrotesk',
        weight: 300,
        size: 40,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h5Regular',
    },
    h4Bold: {
        tag: 'h4',
        family: 'clashGrotesk',
        weight: 400,
        size: 40,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h5Bold',
    },
    h4Fancy: {
        tag: 'h4',
        family: 'orangeAvenue',
        weight: 400,
        size: 34,
        lineHeight: '110%',
        uppercase: true,
        mobileFont: 'h5Fancy',
    },
    h5Regular: {
        tag: 'h5',
        family: 'clashGrotesk',
        weight: 300,
        size: 32,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h6Regular',
    },
    h5Bold: {
        tag: 'h5',
        family: 'clashGrotesk',
        weight: 400,
        size: 32,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h6Bold',
    },
    h5Fancy: {
        tag: 'h5',
        family: 'orangeAvenue',
        weight: 400,
        size: 28,
        lineHeight: '110%',
        uppercase: true,
        mobileFont: 'h6Fancy',
    },
    h6Regular: {
        tag: 'h6',
        family: 'clashGrotesk',
        weight: 400,
        size: 24,
        lineHeight: '100%',
        uppercase: true,
        mobileFont: 'h7Regular',
    },
    h6Bold: {
        tag: 'h6',
        family: 'clashGrotesk',
        weight: 500,
        size: 24,
        lineHeight: '110%',
        uppercase: true,
        mobileFont: 'h7Bold',
    },
    h6Fancy: {
        tag: 'h6',
        family: 'orangeAvenue',
        weight: 400,
        size: 22,
        lineHeight: '110%',
        uppercase: true,
        mobileFont: null,
    },
    h7Regular: {
        tag: 'h6',
        family: 'clashGrotesk',
        weight: 400,
        size: 20,
        lineHeight: '100%',
        uppercase: false,
        mobileFont: null,
    },
    h7Bold: {
        tag: 'h6',
        family: 'clashGrotesk',
        weight: 500,
        size: 20,
        lineHeight: '100%',
        uppercase: false,
        mobileFont: null,
    },
    bodyRegular: {
        tag: 'p',
        family: 'openSauceOne',
        weight: 400,
        size: 14,
        lineHeight: 24,
        uppercase: false,
        mobileFont: null,
    },
    bodyBold: {
        tag: 'p',
        family: 'openSauceOne',
        weight: 500,
        size: 15,
        lineHeight: 24,
        uppercase: false,
        mobileFont: null,
    },
    bodySmallRegular: {
        tag: 'p',
        family: 'openSauceOne',
        weight: 400,
        size: 12,
        lineHeight: 20,
        uppercase: false,
        mobileFont: null,
    },
    bodySmallBold: {
        tag: 'p',
        family: 'openSauceOne',
        weight: 500,
        size: 12,
        lineHeight: 18,
        uppercase: false,
        mobileFont: null,
    },
};
