import {UrlObject} from 'url';

import Link from 'next/link';
import React, {ReactNode} from 'react';

type LinkWrapperProps = {
    href?: string | UrlObject | null;
    children: ReactNode;
};

// Use nextjs Link component only for URL objects and internal app links
// linking to external URLs should be done with plain <a>
const LinkWrapper = ({href, children}: LinkWrapperProps) => {
    if (href) {
        return (
            <Link href={href} passHref>
                {children}
            </Link>
        );
    }

    return <>{children}</>;
};

export default LinkWrapper;
