import classNames from 'classnames';
import {useInView} from 'framer-motion';
import {useRef} from 'react';
import {createGlobalStyle} from 'styled-components';

import {useBreakpoints} from '~components/providers/BreakpointsProvider';
export const enableTextAnimationsClass = 'animateText';
export const disableAnimationsClass = 'disableTextAnimations';

const inViewClass = 'inView';
const outOfViewClass = 'outOfView';

const baseDelay = 0.4;

export function useInViewAnimation() {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: true,
    });
    const {tablet} = useBreakpoints();

    const className = classNames(
        enableTextAnimationsClass,
        isInView ? inViewClass : outOfViewClass,
    );

    return {
        ref,
        isInView,
        animationClassName: className,
        baseDelay,
        staggerDelay: tablet ? 0.2 : 0.05,
    };
}

export const AnimationStyles = createGlobalStyle`
    .${enableTextAnimationsClass} {
        & {

            transition: opacity ease-in-out 0.7s ${baseDelay}s;

            &.${outOfViewClass}:not(.${disableAnimationsClass} *) {
                opacity: 0;
            }

            &.${inViewClass}:not(.${disableAnimationsClass} *) {
                // Remember to not override element styles like opacity
            }
        }
    }
`;
