import styled, {keyframes} from 'styled-components';

import Text, {fonts} from '~components/atoms/Text';

export const StyledDetails = styled(Text.h6Regular).attrs({
    tag: 'details',
})`
    margin: 20px 0;

    &[open] {
        margin-bottom: 0px;

        svg {
            transition: 0.2s;
            transform: rotate(180deg);
        }
    }
`;

export const StyledSummary = styled.summary`
    list-style: none;
    display: flex;
    justify-content: space-between;
`;

const showBox = keyframes`
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(100%);
    }
`;

const showText = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

type DetaildBoxProps = {
    numberOfLinks?: number;
};

export const DetailsBox = styled.div<DetaildBoxProps>`
    ${fonts.h7Regular}
    transform-origin: 0 0;
    transform: scaleY(0);
    animation: ${({numberOfLinks}) =>
        numberOfLinks && `${0.07 * numberOfLinks}s ease-in-out forwards`};
    animation-name: ${showBox};
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.02);
    margin: 16px 0 4px 0;

    p {
        animation: ${({numberOfLinks}) =>
            numberOfLinks && `${0.1 * numberOfLinks}s ease-in-out forwards`};
        animation-name: ${showText};
    }
`;
