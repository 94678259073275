import React from 'react';
import {useRouter} from 'next/router';
import classNames from 'classnames';

import Link, {LinkProps} from '~atoms/Link';
import {FCC} from '~types/global';
import {linkUrl} from '~utils/link';
import {useBreakpoints} from '~components/providers/BreakpointsProvider';
import ThinRightArrow from '~assets/icons/arrow-right-thin.svg';

import {useInViewAnimation} from '../Animations';

import {StyledLink, StyledLinkProps} from './styles';

export type PageLinkProps = LinkProps & StyledLinkProps;
const PageLink: FCC<PageLinkProps> = ({
    active = false,

    underlineOnHover = false,
    withArrow = false,
    children,
    link,
    className,
    ...props
}) => {
    const router = useRouter();
    const breakpoints = useBreakpoints();
    const {ref, animationClassName} = useInViewAnimation();

    return (
        <StyledLink
            underlineOnHover={breakpoints.laptop ? underlineOnHover : false}
            active={active && router.pathname === linkUrl(link)}
            appearance={link?.type || 'link'}
            withArrow={withArrow}
            ref={ref}
        >
            <Link
                link={link}
                className={classNames(className, animationClassName)}
                {...props}
            >
                {children ?? link?.text}
                {link?.type === 'withArrow' && <ThinRightArrow />}
            </Link>
        </StyledLink>
    );
};

export default PageLink;
