import React, {useMemo} from 'react';
import Head from 'next/head';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import {PageQueryPageDataAttributesSeo} from '~types/graphql/PageQuery';

type HTMLHeadProps = {seo?: PageQueryPageDataAttributesSeo};

export const HTMLHead: React.FC<HTMLHeadProps> = ({seo}) => {
    const {pageTitle, pageDescription, pagePoster, robots} = useMemo(
        () => ({
            pageTitle: seo?.title,
            pageDescription: seo?.description,
            pagePoster: imageProps(seo?.poster)?.src,
            robots: Object.entries({
                noindex: seo?.noIndex,
                nofollow: seo?.noFollow,
            })
                .filter(([_, value]) => value)
                .map(([key]) => key)
                .join(','),
        }),
        [seo],
    );

    const metas = useMemo(
        () => [
            ['description', pageDescription],
            ['og:description', pageDescription],
            ['og:image', pagePoster],
            ['og:title', pageTitle],
            ['robots', robots],
            ['og:type', 'website'],
            ['referrer', 'origin'],
            ['viewport', 'width=device-width,initial-scale=1,minimum-scale=1'],
            ['theme-color', '#000000'],
            ['apple-mobile-web-app-status-bar-style', 'black-translucent'],
        ],
        [pageDescription, pagePoster, pageTitle, robots],
    );

    return (
        <Head>
            <meta charSet="utf-8" />
            <link rel="shortcut icon" href="/favicon.svg" type="image/x-icon" />
            <title>{pageTitle}</title>
            {metas.map(
                ([name, content]) =>
                    Boolean(content) && (
                        <meta key={name} name={name} content={content} />
                    ),
            )}
        </Head>
    );
};
