import React, {useState} from 'react';
import Link from 'next/link';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import PageLink from '~components/atoms/PageLink';
import {
    GlobalComponentsQueryFooterDataAttributes,
    GlobalComponentsQueryMenuDataAttributes,
} from '~types/graphql/GlobalComponentsQuery';
import HamburgerIcon from '~assets/icons/hamburger.svg';
import CloseIcon from '~assets/icons/hamburger-close.svg';
import {AboveLaptopWrapper, LaptopWrapper} from '~components/atoms/Wrapper';
import Details from '~components/molecules/Details';
import Socials from '~components/atoms/Socials';
import ContactButtons from '~components/molecules/ContacButtons';
import {Routes} from '~consts/routes';
import {H6} from '~components/download/atoms/Text';
import {disableAnimationsClass} from '~components/atoms/Animations';

import {
    FlexElement,
    FlexLaptopWrapper,
    Links,
    Logo,
    MenuContent,
    MenuToggle,
    MenuWrapper,
    StyledMenu,
} from './styles';
import LinkWrapper from './LinkWrapper';

type MenuProps = GlobalComponentsQueryMenuDataAttributes & {
    socials: GlobalComponentsQueryFooterDataAttributes['socials'];
};

const Menu = ({button, logo, links, socials}: MenuProps) => {
    const [isMenuOpen, toggleMenu] = useState(false);

    return (
        <StyledMenu className={disableAnimationsClass}>
            <MenuContent>
                <MenuWrapper>
                    <Link href={Routes.Homepage} passHref>
                        <Logo>
                            <img {...imageProps(logo)} />
                        </Logo>
                    </Link>

                    <Links open={isMenuOpen}>
                        {links.map((link, index) => (
                            <React.Fragment key={index}>
                                <AboveLaptopWrapper>
                                    <LinkWrapper {...link} />
                                </AboveLaptopWrapper>

                                <LaptopWrapper>
                                    {/*@ts-ignore*/}
                                    {link.children?.length > 0 ? (
                                        <Details
                                            link={link}
                                            onClick={() => toggleMenu(false)}
                                        />
                                    ) : (
                                        <PageLink
                                            link={link}
                                            onClick={() => toggleMenu(false)}
                                        >
                                            <H6>{link.text}</H6>
                                        </PageLink>
                                    )}
                                </LaptopWrapper>
                            </React.Fragment>
                        ))}

                        {button && isMenuOpen && (
                            <FlexLaptopWrapper>
                                <FlexElement />
                                <ContactButtons button={button} />
                                <Socials socials={socials} />
                            </FlexLaptopWrapper>
                        )}
                    </Links>
                </MenuWrapper>

                {button && (
                    <AboveLaptopWrapper>
                        <PageLink link={button}>{button.text}</PageLink>
                    </AboveLaptopWrapper>
                )}

                <MenuToggle onClick={() => toggleMenu((current) => !current)}>
                    {isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
                </MenuToggle>
            </MenuContent>
        </StyledMenu>
    );
};

export default Menu;
