import React from 'react';

import {Subtext} from '~components/organisms/Layout/Menu/styles';
import {LinkQuery} from '~types/graphql/LinkQuery';
import ArrowDown from '~assets/icons/arrow-down.svg';
import PageLink from '~components/atoms/PageLink';

import {DetailsBox, StyledDetails, StyledSummary} from './styles';

type DetailsProps = {
    link: Omit<LinkQuery, '__typename' | 'submenu' | 'appearance' | 'icon'>;
    onClick: () => void;
};

const Details = ({link, onClick}: DetailsProps) => {
    return (
        <StyledDetails key={link.text}>
            <StyledSummary>
                <div>{link.text}</div>
                {link.children?.length !== 0 && <ArrowDown />}
            </StyledSummary>
            {Boolean(link.children?.length !== 0) && (
                <DetailsBox numberOfLinks={link.children?.length}>
                    {link.children?.map(
                        (link, index) =>
                            link && (
                                <PageLink
                                    key={index}
                                    link={link}
                                    onClick={() => onClick()}
                                >
                                    {link.text}
                                    <Subtext>{link.subText}</Subtext>
                                </PageLink>
                            ),
                    )}
                </DetailsBox>
            )}
        </StyledDetails>
    );
};

export default Details;
