import React from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import Text from '~components/atoms/Text';
import Socials from '~components/atoms/Socials';
import EverdomeSlidingLogo from '~assets/icons/everdome-sliding-logo.svg';
import {GlobalComponentsQueryFooterDataAttributes} from '~types/graphql/GlobalComponentsQuery';
import PageLink from '~atoms/PageLink';
import InfiniteAnimatedSlider from '~components/atoms/InfiniteAnimatedSlider';
import Link from '~components/atoms/Link';
import {disableAnimationsClass} from '~components/atoms/Animations';

import {
    AnimatedLogoContainer,
    ButtonIcon,
    ButtonsWrapper,
    ButtonTextWrapper,
    ButtonWrapper,
    FooterContent,
    Icon,
    LeftColumn,
    NestedLink,
    RightColumn,
    StyledFooter,
    TextWrapper,
} from './styles';

const Footer = ({
    links,
    socials,
    buttons,
    theme,
}: GlobalComponentsQueryFooterDataAttributes & {
    theme?: 'light' | 'dark' | undefined | null;
}) => {
    return (
        <StyledFooter theme={theme} className={disableAnimationsClass}>
            <FooterContent>
                <LeftColumn>
                    <ButtonsWrapper>
                        {buttons?.map((button, index) => (
                            <ButtonWrapper key={index}>
                                <Icon {...imageProps(button?.icon.data)} />
                                <ButtonTextWrapper>{button?.text}</ButtonTextWrapper>
                                <PageLink link={button?.button}>
                                    {button?.button.icon.data && (
                                        <ButtonIcon
                                            {...imageProps(button.button.icon.data)}
                                        />
                                    )}

                                    {button?.button.text}
                                </PageLink>
                            </ButtonWrapper>
                        ))}
                    </ButtonsWrapper>
                    <Socials socials={socials} />
                </LeftColumn>
                <RightColumn>
                    {links?.map((item, i) => (
                        <TextWrapper key={`${item?.text}_${i}`}>
                            <Link link={item}>
                                <Text.bodyRegular>{item?.text}</Text.bodyRegular>
                            </Link>
                            {item?.children?.map((item, j) => (
                                <PageLink
                                    link={item}
                                    key={`${item?.text}_${i}_${j}`}
                                >
                                    <NestedLink>{item?.text}</NestedLink>
                                </PageLink>
                            ))}
                        </TextWrapper>
                    ))}
                </RightColumn>
            </FooterContent>
            <AnimatedLogoContainer>
                <InfiniteAnimatedSlider direction="left" pixelsPerSecond={100}>
                    <EverdomeSlidingLogo />
                </InfiniteAnimatedSlider>
            </AnimatedLogoContainer>
        </StyledFooter>
    );
};

export default Footer;
