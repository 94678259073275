import {AnchorHTMLAttributes} from 'react';
import {linkRel, linkTarget, slugToUrl} from '@everdome_io/next-js-helper';

import {LinkQueryChildrenIcon, LinkQueryPage} from '~types/graphql/LinkQuery';
import {PickVariant} from '~types/global';

export type EDLink = Partial<{
    text: string | null;
    url: string | null;
    target: string | null;
    type: string | null;
    page: LinkQueryPage | null;
    icon: LinkQueryChildrenIcon | null;
}>;

type LinkProps = PickVariant<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'target',
    'rel'
>;

export function linkUrl(link?: EDLink | null): string {
    // if (link?.post?.data?.attributes?.seo) {
    //     return slugToUrl(link.post.data.attributes.seo, BLOG_PREFIX);
    // }
    if (link?.page?.data?.attributes?.seo) {
        return slugToUrl(link.page.data.attributes.seo);
    }
    if (link?.url) {
        return link.url;
    }
    return '#';
}

export function linkProps(link?: EDLink | null) {
    const target = linkTarget(link?.target || '');
    let props: LinkProps = {
        href: linkUrl(link),
        target,
    };
    if (target === '_blank') {
        props = {...props, ...linkRel(target)};
    }
    return props;
}
