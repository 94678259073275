import {css} from 'styled-components';

const gap = 6;
const width = 1.2;

export const bottomDashedLineCss = css`
    background: linear-gradient(
        to right,
        transparent 0%,
        transparent 80%,
        var(--line-color, var(--black)) 80%,
        var(--line-color, var(--black)) 100%
    );
    background-repeat: repeat-x;
    background-size: ${gap}px ${width}px;
    background-position-y: bottom;
`;

export const topDashedLineCss = css`
    background: linear-gradient(
        to right,
        transparent 0%,
        transparent 80%,
        var(--line-color, var(--black)) 80%,
        var(--line-color, var(--black)) 100%
    );
    background-repeat: repeat-x;
    background-size: ${gap}px ${width}px;
    background-position-y: top;
`;

export const rightDashedLineCss = css`
    background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 80%,
        var(--line-color, var(--black)) 80%,
        var(--line-color, var(--black)) 100%
    );

    background-repeat: repeat-y;
    background-size: ${width}px ${gap}px;
    background-position-x: right;
`;

export const leftDashedLineCss = css`
    background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 80%,
        var(--line-color, var(--black)) 80%,
        var(--line-color, var(--black)) 100%
    );
    background-repeat: repeat-y;
    background-size: ${width}px ${gap}px;
    background-position-x: left;
`;
