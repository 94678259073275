import styled from 'styled-components';

import {
    bottomDashedLineCss,
    topDashedLineCss,
} from '~components/atoms/DashedLine/styles';
import {StyledLink} from '~components/atoms/PageLink/styles';

export const ContactButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding: 24px 0;
    ${bottomDashedLineCss}
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        ${topDashedLineCss};
    }
`;

export const ContactButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    div {
        width: 100%;
    }
    svg {
        width: 16px;
        height: 16px;
    }
    ${StyledLink} {
        padding: 6px 12px;
    }
`;
