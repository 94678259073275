import styled, {css} from 'styled-components';

import {fonts} from '../Text';
import {underlineActiveCss, underlineOnHoverCss} from '../UnderlineOnHover/styles';

export type StyledLinkProps = {
    active?: boolean;
    appearance?: string | null;
    underlineOnHover?: boolean;
    withArrow?: boolean;
};

export const StyledLink = styled.div<StyledLinkProps>`
    width: max-content;
    max-width: 100%;
    border-radius: 20px;

    cursor: pointer;
    position: relative;

    ${({appearance, underlineOnHover, active, withArrow}) => {
        switch (appearance) {
            case 'link':
                return css`
                    ${underlineOnHover &&
                    css`
                        ${underlineOnHoverCss}
                    `}

                    ${active &&
                    css`
                        ${underlineOnHoverCss}
                        ${underlineActiveCss}
                    `}

        ${withArrow &&
                    css`
                        a > * {
                            display: flex;
                            flex-direction: row;

                            align-items: center;
                            gap: 8px;

                            &::after {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                min-width: 24px;
                                height: 24px;
                                min-height: 24px;
                                background: url('/assets/icons/arrow-right.svg')
                                    no-repeat center;
                                background-size: contain;
                            }
                        }
                    `}
                `;
            case 'buttonFilled':
                return css`
                    padding: 9px 24px;
                    background: var(--black);
                    color: var(--white);
                    text-align: center;
                    border: 1px solid var(--black);
                    ${fonts.h7Regular}

                    &, img {
                        transition: all 600ms ease-in-out;
                    }

                    :hover {
                        background: var(--white);
                        border-color: var(--black);
                        color: var(--black);

                        img {
                            filter: invert();
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: inherit !important;
                    }
                `;
            case 'buttonStroked':
                return css`
                    padding: 9px 24px;
                    /* background: var(--white); */
                    color: var(--black);
                    text-align: center;
                    border: 1px solid var(--black);
                    ${fonts.h7Regular}

                    &, img {
                        transition: all 600ms ease-in-out;
                    }

                    :hover {
                        background: var(--black);
                        border-color: var(--black);
                        color: var(--white);

                        img {
                            filter: invert();
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: inherit !important;
                    }
                `;
            case 'withArrow':
                return css`
                    ${fonts.h7Regular}
                    transition: color 600ms ease-in-out;

                    svg {
                        margin-left: 8px;
                        position: relative;
                        top: 0.075em;
                    }

                    :hover {
                        color: var(--orange);
                    }
                `;
        }
    }}
`;
