export enum ApiRoutes {
    Articles = '/api/articles',
}

export enum Routes {
    Homepage = '/',
    Profile = '/profile',
    News = '/news',
    NewsPost = '/news/[slug]',
}

export enum IdmApiRoutes {
    Nonce = '/v1/auth/nonce',
    Login = '/v1/auth/login',
    RefreshToken = '/v1/auth/refresh-token',

    GameVerify = '/v1/game/verify',
    GameDownload = '/v1/game/download',
    Me = '/v1/auth/me',
}
