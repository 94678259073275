import styled from 'styled-components';

import {above, below} from '~config/breakpoints';

export const MobileWrapper = styled.div`
    ${above.desktop} {
        display: none;
    }
`;

export const LaptopWrapper = styled.div`
    ${above.laptop} {
        display: none;
    }
`;

export const AboveLaptopWrapper = styled.div`
    ${below.laptop} {
        display: none;
    }
`;

export const DesktopWrapper = styled.div`
    ${below.desktop} {
        display: none;
    }
`;
