import {css} from 'styled-components';

export const underlineTransitionCss = css`
    &::after {
        transition: transform ease-in-out 500ms;
    }
`;

export const underlineInactiveCss = css`
    &::after {
        transform: translateX(calc(-1 * calc(100% + 1px)));
    }
`;

export const underlineActiveCss = css`
    &::after {
        transform: translateX(0);
    }
`;

export const underlineOnHoverCss = css`
    --underline-width: 2px;
    position: relative;
    display: block;
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% calc(100% + var(--underline-width) + 1px),
        0% calc(100% + var(--underline-width) + 1px)
    );

    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
        height: var(--underline-width);
        background: var(--underline-color, currentColor);
    }

    ${underlineTransitionCss}
    ${underlineInactiveCss}

    &:hover {
        ${underlineActiveCss}
    }
`;
