import styled from 'styled-components';

import {fonts} from '~components/atoms/Text';
import {above} from '~config/breakpoints';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    --topbar-height: 56px;

    ${Content} {
        padding-top: var(--topbar-height);
    }

    ${above.tablet} {
        --topbar-height: 72px;

        ${Content} {
            padding-top: calc(var(--topbar-height) + 43px);
        }
    }

    --h-padding: 16px;

    ${above.tablet} {
        --h-padding: 64px;
    }

    ${above.laptop} {
        --h-padding: 108px;
    }

    --width-limit: 1920px;

    --h-margin: max(calc((100vw - var(--width-limit)) / 2), 0px);

    margin-inline: var(--h-margin);

    ${fonts.bodyRegular}
`;
